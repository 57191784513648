.App {
  background: #ececec;
  min-height:  100vh;
}

.switcher {
  margin: 20px 0;
}

.card {
  max-height: 630px;
}

.chat-window {
  overflow: auto;
  padding: 20px;
}

.message {
  max-width: 300px;
  margin: 0 0 20px;
}

.bubble {
  border-radius: 20px;
  background: dodgerblue;
  color: white;
  padding: 10px;
}

.sender {
  margin: 5px 0 0 10px;
  font-style: italic;
}

.remove {
  cursor: pointer;
  margin-top: auto;
  margin-left: 10px;
}

.fa-trash {
  color: indianred;
}

.bubble img {
  max-width: 100%;
  border-radius: 10px;
}

.col-4 .card-body {
  overflow: auto;
  margin: 20px;
}